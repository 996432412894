import React from "react"
import styled from "styled-components"
import NavItem from "./nav-item"
import { graphql, Link, useStaticQuery } from "gatsby"
import { page } from "../utils/siteVars"

const StyledNavbar = styled.nav`
  max-width: ${page.siteWidth};
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  position: relative;
  bottom: 20vh;
  margin-bottom: -15vh;
  @media (max-width: 768px) {
    display: none;
  }
`

const Navbar = () => {
  const fluidImages = useStaticQuery(graphql`
    query navQuery {
      home: file(relativePath: { eq: "man-1-small.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      find: file(relativePath: { eq: "tech-2.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      tech: file(relativePath: { eq: "wash-container-small.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      contact: file(relativePath: { eq: "contact-small.jpg" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const navItems = [
    {
      text: "Hem",
      link: "/",
      image: "/images/man-1.jpg",
      fluid: fluidImages.home.childImageSharp.fluid,
    },
    {
      text: "Hitta tvätt",
      link: "/hitta",
      image: "/images/wash-side.jpg",
      fluid: fluidImages.find.childImageSharp.fluid,
    },
    {
      text: "Produkter",
      link: "/produkter",
      image: "/images/tech-1.jpg",
      fluid: fluidImages.tech.childImageSharp.fluid,
    },
    {
      text: "Kontakt",
      link: "/kontakt",
      image: "/images/contact.jpg",
      fluid: fluidImages.contact.childImageSharp.fluid,
    },
  ]

  return (
    <StyledNavbar>
      {navItems.map(item => (
        <Link to={item.link} key={item.link}>
          <NavItem image={item.image} text={item.text} fluid={item.fluid} />
        </Link>
      ))}
    </StyledNavbar>
  )
}

export default Navbar
