import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledButton = styled.button`
  font-size: 1.1rem;
  background: ${colors.green};
  color: ${colors.white};
  padding: ${props => props.padding || "1rem 4rem"};

  font-weight: 400;
  border: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;

  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
  transition: 200ms ease-in-out;
  &:hover {
    border: 1px solid ${colors.mediumGrey};
    background: ${colors.lightGreen};
    color: ${colors.black};
  }
`

const Button = props => {
  return (
    <StyledButton
      onClick={props.onClick}
      type={props.type}
      padding={props.padding}
    >
      {props.children}
    </StyledButton>
  )
}

export default Button
