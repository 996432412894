import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"
import MapLink from "./mapLink"
import Navbar from "./navbar"
import NavbarMobile from "./navbar-mobile"

const StyledHeader = styled.div`
  position: relative;

  .header-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1rem 1rem;
    img {
      width: 200px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .header-map-link {
    padding: 1rem;
    @media (max-width: 768px) {
      position: relative;
      top: 4rem;
    }
  }
  .header-background-image {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;
    padding-bottom: 8vh;
    h1 {
      color: ${colors.white};
      font-size: 3rem;
      margin: 0;
    }
    @media (max-width: 768px) {
      h1 {
        font-size: 2rem;
        text-align: center;
      }
    }
  }
`

const Header = props => {
  const o = 0.4
  const background = [
    `linear-gradient(rgba(0,0,0,${o}), rgba(0,0,0,${o}))`,
    props.headerImage,
  ]
  let hideCTA = false
  if (typeof window !== `undefined`) {
    const currentLocation = window.location.href.split("/")[3]
    if (currentLocation === "hitta") hideCTA = true
  }
  return (
    <StyledHeader>
      <div className="header-logo">
        <Link to="/">
          <img src="/images/Logga.svg" alt="cykeltvätten" />
        </Link>
      </div>
      <BackgroundImage fluid={background}>
        <div className="header-background-image">
          <h1>{props.headerText}</h1>
          {hideCTA ? null : (
            <div className="header-map-link">
              <Link to="/hitta">
                <MapLink />
              </Link>
            </div>
          )}
        </div>
      </BackgroundImage>
      <Navbar />
      <NavbarMobile />
    </StyledHeader>
  )
}

export default Header
