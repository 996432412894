import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledH1 = styled.h1`
  color: ${colors.green};
  text-align: center;
  ${props => (props.fontSize ? "font-size: " + props.fontSize + ";" : "")}
  img {
    position: relative;
    left: 1rem;
    top: 0.3rem;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

const H1 = props => {
  return (
    <StyledH1 fontSize={props.fontSize} alignLeft={props.alignLeft}>
      {props.children}
    </StyledH1>
  )
}

export default H1
