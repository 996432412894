import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"
import Button from "./button"
import Narrow from "./narrow"

const StyledFooter = styled.footer`
  background: ${colors.darkGrey};
  color: ${colors.white};
  margin-top: 4rem;
  padding: 1rem;
  .footer-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5rem;
    a {
      display: block;
    }
    p {
      margin: 0;
      white-space: nowrap;
    }
    p.strong {
      font-weight: 700;
      margin-top: 1rem;
      margin-bottom: 0.3rem;
    }
    button {
      margin-top: 0.5rem;
    }
  }
  .footer-left {
    a {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  img {
    width: 100%;
    &.drop {
      margin-bottom: 1rem;
      width: 15%;
    }
  }
  @media (max-width: 768px) {
    .footer-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <Narrow>
        <div className="footer-inner">
          <div className="footer-left">
            <Link to="/">
              <img
                src="/images/Droppe.svg"
                alt="cykeltvätten"
                className="drop"
              />
              <img src="/images/Logga.svg" alt="cykeltvätten" />
            </Link>
            <p>Bicycle Wash Sverige AB</p>
            <p>Innehar F-skatt</p>
          </div>
          <div className="footer-mid-left">
            <p className="strong">Kontakt</p>
            <a href="mailto:info@cykeltvatten.se">info@cykeltvatten.se</a>
            <Link to="/kontakt">
              <Button padding="0.5rem 1rem">Kontakta oss</Button>
            </Link>
          </div>
          <div className="footer-mid-right">
            <p className="strong">Besöksadress</p>
            <p>Beckombergavägen 188</p>
            <p>168 53 Bromma</p>
          </div>
          <div className="footer-right">
            <p className="strong">Följ oss</p>
            <a
              href="https://www.facebook.com/Cykeltv%C3%A4ttense-581584745555634"
              target="__blank"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/cykeltvatten.se/"
              target="__blank"
            >
              Instagram
            </a>
          </div>
        </div>
      </Narrow>
    </StyledFooter>
  )
}

export default Footer
