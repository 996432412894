import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledMapLink = styled.button`
  background: ${colors.green};
  color: ${colors.white};
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  img {
    width: 25px;
    margin-right: 0.5rem;
    transition: 200ms ease-in-out;
  }
  transition: 200ms ease-in-out;
  &:hover {
    background: ${colors.lightGreen};
    color: ${colors.black};
    img {
      filter: invert(1);
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
`

const MapLink = () => {
  return (
    <StyledMapLink>
      <img src="/images/map.png" alt="karta" />
      Hitta din tvätt
    </StyledMapLink>
  )
}

export default MapLink
