import React from "react"
import styled from "styled-components"
import { page } from "../utils/siteVars"

const StyledNarrow = styled.div`
  max-width: ${page.siteWidth};
  margin: auto;
`

const Narrow = props => {
  return <StyledNarrow>{props.children}</StyledNarrow>
}

export default Narrow
