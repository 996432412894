import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"
import Hamburger from "./hamburger"

const StyledNavbarMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    .navbar-mobile-inner {
      position: relative;
      z-index: 5;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
      display: flex;
      background: ${colors.white};
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      img {
        margin-top: 0.2rem;
        margin-bottom: -0.3rem;

        width: 150px;
      }
    }
    .drop-down {
      position: relative;
      z-index: 4;
      background: ${colors.white};
      display: flex;
      justify-content: center;
      text-align: center;
      transition: 200ms ease-in-out;
      max-height: 0;
      overflow: hidden;
      &.active {
        max-height: 50vh;
      }
      ul {
        width: 100%;
      }
      .nav-item-mobile {
        li {
          padding: 0.8rem 0;
          position: relative;
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            width: 100%;
            background: ${colors.mediumGrey};
            content: "";
          }
        }
      }
    }
  }
`

const NavbarMobile = () => {
  const [open, setOpen] = useState(false)

  const navItems = [
    {
      text: "Hem",
      link: "/",
      image: "/images/temp.jpg",
    },
    {
      text: "Hitta tvätt",
      link: "/hitta",
      image: "/images/temp.jpg",
    },
    {
      text: "Produkter",
      link: "/produkter",
      image: "/images/temp.jpg",
    },
    {
      text: "Kontakt",
      link: "/kontakt",
      image: "/images/temp.jpg",
    },
  ]

  return (
    <StyledNavbarMobile>
      <div className="navbar-mobile-inner">
        <div className="navbar-mobile-left">
          <Link to="/">
            <img src="/images/logo.png" alt="cykeltvätten" />
          </Link>
        </div>
        <div className="navbar-mobile-right">
          <Hamburger
            color={colors.black}
            active={open}
            onClick={() => {
              setOpen(!open)
            }}
          />
        </div>
      </div>
      <div className={`drop-down ${open ? "active" : ""}`}>
        <ul>
          {navItems.map(item => (
            <Link to={item.link} key={item.link} className="nav-item-mobile">
              <li>{item.text}</li>
            </Link>
          ))}
        </ul>
      </div>
    </StyledNavbarMobile>
  )
}

export default NavbarMobile
