export const colors = {
  white: "#FFFFFF",
  black: "#000",
  green: "#00A743",
  lightGreen: "#c9ecd7",
  mediumGrey: "#c7c7c7",
  lightGrey: "#f0f0f0",
  darkGrey: "#333",
}

export const page = {
  siteWidth: "1000px",
}

// export const db = "http://localhost:9000/.netlify/functions/index/"
export const db = "https://cykeltvatten.netlify.app/.netlify/functions/index/"
