import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Footer from "./footer"
import "../utils/index.css"
import Header from "./header"

const StyledLayout = styled.div`
  .app-inner {
    padding: 0 1rem;
    @media (max-width: 768px) {
    }
  }
`

const Layout = props => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <title>{props.helmet ? props.helmet + " | " : ""}Cykeltvätten</title>
      </Helmet>
      <StyledLayout>
        <Header headerImage={props.headerImage} headerText={props.headerText} />
        <div className="app-inner">{props.children}</div>
        <Footer />
      </StyledLayout>
    </>
  )
}

export default Layout
