import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"
import Img from "gatsby-image"

const StyledNavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 120px;
  color: ${colors.white};
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  img,
  .nav-fluid-image-container {
    object-fit: cover;
    width: 80%;
    height: 100%;

    max-width: 200px;
    border-radius: 1rem;
    border: 2px solid transparent;
    transition: 200ms ease-in-out;
  }

  &:hover {
    img {
      border: 2px solid ${colors.green};
    }
  }
`

const NavItem = props => {
  return (
    <StyledNavItem>
      <p>{props.text}</p>
      {/* <img src={props.image} alt={props.text} /> */}
      <div className="nav-fluid-image-container">
        <Img fluid={props.fluid} alt={props.text} />
      </div>
    </StyledNavItem>
  )
}

export default NavItem
